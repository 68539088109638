<template>
  <div class="py-8">
    <div class="px-6">
      <v-app-bar color="transparent" flat>
        <app-bar-nav-icon />
        <v-toolbar-title class="breadcrumbs d-flex align-center">
          <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
          <router-link
            :to="{ name: 'tradies', query: { type: 'active' } }"
            class="text-decoration--none"
          >
            Back to tradies
          </router-link>
        </v-toolbar-title>
      </v-app-bar>
    </div>

    <div v-if="form" class="mt-5 px-12 mb-10">
      <div class="display-2 font-weight-bold my-6 primary--text">
        {{ form.email }}
      </div>

      <v-container :fluid="true" class="grey lighten-5">
        <v-form v-if="form" ref="form">
          <v-row class="mb-2">
            <v-col xs="12" md="4">
              <label class="text-field-label">Contact Name</label>
              <v-text-field
                v-model="form.primary_contact_name"
                :error-messages="form.$getError('primary_contact_name')"
                class="mt-2"
                flat
                required
                solo
              />

              <label class="text-field-label">Business Name</label>
              <v-text-field
                v-model="form.business_name"
                :error-messages="form.$getError('business_name')"
                class="mt-2"
                flat
                required
                solo
              />
              <label class="text-field-label">Australian Business Number</label>
              <v-text-field
                v-model="form.abn"
                :error-messages="form.$getError('abn')"
                class="mt-2"
                flat
                required
                solo
              />
            </v-col>

            <v-col sm="12" md="4" offset-md="0">
              <label class="text-field-label">License Number</label>
              <v-text-field
                v-model="form.license_number"
                :error-messages="form.$getError('license_number')"
                class="mt-2"
                flat
                required
                solo
                readonly
              />

              <label class="text-field-label">Trade License</label>
              <v-text-field
                v-model="form.trade_license"
                :error-messages="form.$getError('trade_license')"
                class="mt-2"
                flat
                required
                solo
                readonly
              />

              <label class="text-field-label">Business Type</label>
              <v-text-field
                v-model="form.business_type"
                :error-messages="form.$getError('business_type')"
                class="mt-2"
                flat
                readonly
                required
                solo
              />
            </v-col>

            <v-col sm="12" md="4" offset-md="0">
              <label class="text-field-label">Contact Number</label>
              <v-text-field
                v-model="form.business_contact_number"
                :error-messages="form.$getError('business_contact_number')"
                class="mt-2"
                flat
                required
                solo
              />
              <label class="text-field-label">Website</label>
              <v-text-field
                v-model="form.website"
                :error-messages="form.$getError('website')"
                class="mt-2"
                flat
                required
                solo
              />

              <label class="text-field-label"> Current Address: </label>
              <AddressLookup
                class="mt-2"
                v-model="form.full_address"
                @change="selectedAddress"
                :error-messages="form.$getError('address')"
                :default-value="form.user_address"
                placeholder="Update current address"
                return-object
                hide-no-data
                clearable
                solo
                flat
              />

              <!-- <v-autocomplete
                v-model="new_address"
                :items="itemsData"
                :loading="isLoading"
                :search-input.sync="search"
                color="white"
                hide-no-data
                hide-selected
                item-text="Description"
                item-value="API"
                placeholder="Update current address"
                label=""
                class="mt-2"
                v-model="form.full_address"
                @change="selectedAddress"
                :error-messages="form.$getError('address')"
              /> -->
            </v-col>
          </v-row>

          <div>
            <v-btn
              :loading="form.$busy"
              class="mr-4 px-6"
              color="primary"
              depressed
              height="40px"
              @click="updateTradieDetails"
              >Update
            </v-btn>

            <v-btn
              :loading="form.$busy"
              class="mr-4 px-6"
              color="red"
              dark
              depressed
              height="40px"
              @click="deleteTradieData()"
              >Delete
            </v-btn>
          </div>
        </v-form>
      </v-container>

      <div class="display-2 font-weight-bold my-6 text-capitalize">
        Bank Details
      </div>

      <v-row>
        <v-col class="pa-0" cols="12">
          <blue-divider></blue-divider>
          <v-simple-table>
            <thead>
              <tr>
                <th class="title text-center" width="25%">
                  <strong class="black--text">Bank Name</strong>
                </th>
                <th class="title text-center" width="25%">
                  <strong class="black--text">Bank Account Name</strong>
                </th>
                <th class="title text-center" width="25%">
                  <strong class="black--text">Bank Account Number</strong>
                </th>
                <th class="title text-center" width="25%">
                  <strong class="black-text">BSB</strong>
                </th>
              </tr>
            </thead>
          </v-simple-table>
          <v-simple-table>
            <tbody>
              <tr
                v-for="bankAccount in this.tradie.bank_accounts"
                :key="bankAccount.bank_account_id"
              >
                <td class="text-center" width="25%">
                  <strong class="subtitle-1">
                    {{ bankAccount.bank_name }}
                  </strong>
                </td>
                <td class="text-center" width="25%">
                  <strong class="subtitle-1">{{
                    bankAccount.bank_account_name
                  }}</strong>
                </td>
                <td class="text-center" width="25%">
                  <strong class="subtitle-1">{{
                    bankAccount.bank_account_number
                  }}</strong>
                </td>
                <td class="text-center" width="25%">
                  <strong class="subtitle-1">{{
                    bankAccount.bank_state_branch
                  }}</strong>
                </td>
              </tr>
              <tr v-if="loading">
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
                <td class="pl-0">
                  <v-skeleton-loader type="list-item" />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>

    <v-overlay :value="loading" absolute>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>

    <DeleteTradieDialog ref="deleteTradieModal" @confirm="tradieDeleted" />
  </div>
</template>

<script>
import { mdiChevronLeft, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { get } from 'lodash'

import DeleteTradieDialog from '@/components/modals/DeleteTradieDialog'
import AddressLookup from '@/components/elements/AddressLookup'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import BlueDivider from '../../../components/BlueDivider'
import Form from '@/utils/form'

export default {
  name: 'TradieDetails',

  components: {
    DeleteTradieDialog,
    AddressLookup,
    AppBarNavIcon,
    BlueDivider,
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        check: mdiCheck,
        alert: mdiAlertCircleOutline,
      },
      loading: false,
      deleteDialog: false,
      form: null,
      showCropperModal: false,
      tabItems: [{ tab: 'Info' }],
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      isLoading: false,
      search: null,
      new_address: [],
      entries: [],

      newSelectedAddress: {},
    }
  },

  computed: {
    ...mapState({
      tradie: (state) => state.tradie.tradieDetails,
      authUser: (state) => state.auth.user,
    }),
    ...mapGetters('tradie', ['addressList']),
    itemsData() {
      return this.entries.map((entry) => {
        const Description = entry.fullAddress

        return Object.assign({}, entry, { Description })
      })
    },
  },

  methods: {
    ...mapActions({
      getTradieDetails: 'tradie/getTradieDetails',
      updateTradie: 'tradie/updateTradie',
      deleteTradie: 'tradie/deleteTradie',
      searchAddress: 'tradie/checkAddress',
    }),

    ...mapMutations({
      clearTradieDetails: 'tradie/clearTradieDetails',
    }),

    async getTradie() {
      this.loading = true
      await this.getTradieDetails(this.$route.params.id)
      this.form = new Form(this.tradie)
      this.loading = false
    },

    async updateTradieDetails() {
      this.form.$busy = true
      this.form.$clearErrors()

      if (this.new_address) {
        this.form.update_address = this.newAddress()
      }

      this.updateTradie(this.form)
        .then(() => {
          this.form.$busy = false
          this.new_address = []
          this.getTradie()
          this.showSnackbar('Tradie details successfully updated!', 'success')
        })
        .catch((err) => {
          this.form.$busy = false
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
    },

    newAddress() {
      return {
        full_address: this.new_address.full_address,
        floor_or_unit_number: this.new_address.floor_level_number,
        street_number: this.new_address.street_number,
        street_type: this.new_address.street_type,
        street_name: this.new_address.street_name,
        suburb: this.new_address.locality,
        state: this.new_address.state,
        postcode: this.new_address.postcode,
        longitude: get(this.new_address, 'longitude', ''),
        latitude: get(this.new_address, 'latitude', ''),
      }
    },

    async deleteTradieData() {
      this.$refs.deleteTradieModal.setTradie(this.tradie).open()
    },

    tradieDeleted() {
      this.showSnackbar('Tradie deleted successfully!')
      this.clearTradieDetails()
      this.$router.replace({ name: 'tradies', query: { type: 'active' } })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    selectedAddress(address) {
      this.new_address = address
    },
  },

  created() {
    this.getTradie()
  },

  destroyed() {
    this.clearTradieDetails()
  },

  watch: {
    $route() {
      this.getTradie()
    },
    search(val) {
      if (this.addressList.length > 0) return

      if (this.isLoading) return

      this.isLoading = true

      this.searchAddress({
        full_address: val,
      })
        .then(() => {
          this.entries = this.addressList
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>
