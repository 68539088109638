<template>
  <v-dialog
    v-model="dialog"
    persistent
    :overlay="false"
    :max-width="hasPendingInvoices ? '450px' : '350px'"
    transition="dialog-transition"
  >
    <v-card v-if="tradie" rounded="lg">
      <v-toolbar color="primary" dark dense flat>
        <v-toolbar-title class="white--text"> Delete tradie </v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-4 body-1">
        <div v-if="tradie.pending_invoices_count" class="mb-3">
          <span v-if="tradie" class="font-weight-bold primary--text">
            {{ tradie.primary_contact_name }}
          </span>
          <span v-if="tradie" class="font-italic blue-grey--text lighten-3">
            ({{ tradie.user.email }})
          </span>
          has a total of
          <span v-if="tradie" class="error--text font-weight-bold">
            {{ tradie.pending_invoices_count }}
          </span>
          pending invoice/s.
        </div>

        <div>Are you sure you want to continue?</div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="pa-4">
        <v-btn color="error px-6" @click="close()" :loading="deleting" text>
          Cancel
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="primary px-12"
          @click="confirm()"
          :loading="deleting"
          dark
        >
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'DeleteTradieDialog',

  data() {
    return {
      dialog: false,
      tradie: null,
      deleting: false,
    }
  },

  computed: {
    hasPendingInvoices() {
      return this.tradie ? this.tradie.pending_invoices_count : false
    },
  },

  methods: {
    ...mapActions({
      deleteTradie: 'tradie/deleteTradie',
    }),

    setTradie(tradie) {
      this.tradie = tradie

      return this
    },

    open() {
      this.dialog = true
    },

    close() {
      this.dialog = false
    },

    confirm() {
      this.deleting = true

      this.deleteTradie(this.tradie.user.id).then(() => {
        setTimeout(() => {
          this.deleting = false
          this.$emit('confirm')
          this.close()
        }, 700)
      })
    },
  },
}
</script>
