<template>
  <hr />
</template>

<script>
export default {}
</script>

<style>
hr {
  border-width: 5px;
  border-style: solid;
  border-color: dodgerblue;
}
</style>
